import domReady from '@roots/sage/client/dom-ready';
import Swiper from 'swiper/bundle';
import { Fancybox } from '@fancyapps/ui';
Fancybox.bind();
import { initTieredAccordion } from './navigation.js';

/**
 * Application entrypoint
 */
domReady(async () => {


  // Nav Toggle

  function toggleOffCanvasMenu() {
    let menuButton = document.querySelector('#toggle-nav');
    let offCanvasMenu = document.querySelector('#off-canvas-menu');
    let modalBackdrop = document.querySelector('[modal_backdrop]');
    menuButton.classList.toggle('active');
    offCanvasMenu.classList.toggle('off-canvas-open');
    modalBackdrop.classList.toggle('modal-backdrop-open');
  }

  function initOffCanvasMenu() {
    let toggle_btn = document.querySelector('#toggle-nav');
    if (toggle_btn !== null) {
      toggle_btn.addEventListener('click', toggleOffCanvasMenu);
    }

    let modalBackdrop = document.querySelector('[modal_backdrop]');
    if (modalBackdrop !== null) {
      modalBackdrop.addEventListener('click', toggleOffCanvasMenu);
    }
  }
  initOffCanvasMenu();
  initTieredAccordion();

  // Additional Off Canvas Buttons 

  function openOffCanvas(e) {
    const id = e.target.getAttribute('off_canvas_id');
    console.log(id);
    const canvas = document.querySelector(`.off-canvas[off_canvas_id='${id}']`);
    if (canvas) {
      canvas.classList.add('off-canvas-open');
      document.querySelector('body').classList.add('modal-open');
    }
  }

  function closeOffCanvas(e) {
    const id = e.target.getAttribute('off_canvas_id');
    const canvas = document.querySelector(`.off-canvas[off_canvas_id='${id}']`);
    if (canvas) {
      canvas.classList.remove('off-canvas-open');
      document.querySelector('body').classList.remove('modal-open');
    }
  }

  function initOffCanvases() {
    let offCanvasOpenArray = document.querySelectorAll('[off_canvas_open]');
    offCanvasOpenArray.forEach((element) => {
      element.addEventListener('click', openOffCanvas);
    })
    let offCanvasCloseArray = document.querySelectorAll('[off_canvas_close]');
    offCanvasCloseArray.forEach((element) => {
      element.addEventListener('click', closeOffCanvas);
    })
  }
  initOffCanvases();

  /* 
    let menu_links = document.querySelectorAll('#menu-intelliworld-menu .sub-menu a');
    
    menu_links.forEach((link) => {
  
      link.addEventListener('click', function (event) {
        console.log(event);
        console.log(event.target);
        var x = window.matchMedia("(max-width: 700px)");
        
        if (x.matches) {
          console.log('x matches');
          var offCanvas = document.getElementById('off-canvas');
          //offCanvas.classList.toggle('hidden');
          offCanvas.classList.toggle('-right-full');
          offCanvas.classList.toggle('right-0');
          offCanvas.classList.toggle('translate-x-full');
  
        }
      
      
  
    }); */

  // Different Swipers
  const mb_swiper = new Swiper('.mb-swiper', {
    slidesPerView: 1,
    speed: 1500,
    autoplay: {
      delay: 5000,
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    loop: true,
  });

  // Different Swipers


  let mediaTextSliderSpeed = 1500;
  let mediaTextAutoplayInterval = 5000;
  const mediaTextSlider = document.querySelector('.mb-swiper-media-text');
  if(mediaTextSlider){
	if(mediaTextSlider.getAttribute('slider_speed')){
		mediaTextSliderSpeed = parseInt(mediaTextSlider.getAttribute('slider_speed'));
	}
	if(mediaTextSlider.getAttribute('autoplay_interval')){
		mediaTextAutoplayInterval = parseInt(mediaTextSlider.getAttribute('autoplay_interval'));
	}
  }

  const mb_swiper_media_text = new Swiper('.mb-swiper-media-text', {
    slidesPerView: 1,
    speed: mediaTextSliderSpeed,
    autoplay: {
      delay: mediaTextAutoplayInterval,
    },
    effect: 'fade',
    pagination: {
      el: '.swiper-pagination--mb-swiper-media-text',
      clickable: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    loop: true,
  });

  // Different Swipers
  const mb_carousel = new Swiper('.mb-carousel', {
    slidesPerView: 5,
    speed: 1000,
    navigation: {
      nextEl: '.mb-carousel-button-next',
      prevEl: '.mb-carousel-button-prev',
    },
    autoplay: {
      delay: 5000,
    },
    loop: false,
    breakpoints: {
      // when window width is >= 320px
      300: {
        slidesPerView: 1,
        spaceBetween: 20,

      },
      // when window width is >= 480px
      700: {
        slidesPerView: 2,
        spaceBetween: 30,

      },
      // when window width is >= 640px
      900: {
        slidesPerView: 3,
        spaceBetween: 40
      },
      1400: {
        slidesPerView: 5,
        spaceBetween: 40
      }
    }
  });

  const testimonial_swiper = new Swiper('.mb-swiper-testimonial', {
    slidesPerView: 1,
    speed: 1500,
    autoplay: {
      delay: 5000,
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    loop: true,
    // modules: [Autoplay, Navigation, Pagination],
  });

  const banner_swiper = new Swiper('.mb-swiper-banner', {
    slidesPerView: 1,
    speed: 1500,
    effect: 'fade',
    autoplay: {
      delay: 5000,
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    loop: true,
    // modules: [Autoplay, Navigation, Pagination, EffectFade],
  });

  /*   const latestPropertiesSwiper = new Swiper('.swiper--latest-properties', {
      slidesPerView: 1,
      loop: false,
      spaceBetween: 20,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      breakpoints: {
        768: {
          slidesPerView: 3,
        },
        1200: {
          slidesPerView: 4,
        },
      },
    }); */


  // document.querySelectorAll('#menu-intelliworld-menu a').forEach(function (li) {
  //   li.addEventListener('click', function (e) {
  //     //e.preventDefault();
  //     var thePath = this.href;
  //     console.log(thePath.substring(thePath.lastIndexOf('/')));
  //     //location.hash = "#" + this.href.substring(this.href.lastIndexOf('/') + 1);
  //   });
  // });



  /* # Header > transparency*/
  window.onscroll = function () {
    if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
      document.querySelector('#header.mb-header-fixed').classList.remove('transparent');
      document.querySelector('.menu-button-container').classList.add('scrolled');
      if (document.querySelector('.off-canvas-menu-stamp-image-container')) {
        document.querySelector('.off-canvas-menu-stamp-image-container').classList.add('scrolled');
      }


    } else {
      document.querySelector('#header.mb-header-fixed').classList.add('transparent');
      document.querySelector('.menu-button-container').classList.remove('scrolled');
      if (document.querySelector('.off-canvas-menu-stamp-image-container')) {
        document.querySelector('.off-canvas-menu-stamp-image-container').classList.remove('scrolled');
      }
    }
  };

  /* # Header > Off Canvas Menu */
  // Menu Hover Fade
  function menuHoverSwitchImage(e) {
    let newImageId = parseInt(e.currentTarget.getAttribute('menu_hover_switch_id'));
    let newImage = document.querySelector(`img[menu_hover_switch_id='${newImageId}']`);
    let currentImage = document.querySelector('[hover_menu_fade_image].active');

    // prevent switch if same menu item is re-hovered or if the new image is not found
    if ((newImage !== currentImage) && newImage) {
      newImage.classList.add('active');
      if (currentImage) {
        currentImage.classList.remove('active');
      }
    }
  }

  // set-up wp menu with ids (These match the Menu Image)
  let menuItems = document.querySelectorAll('#menu-main > li');
  if (menuItems) {
    menuItems.forEach((element, i) => {
      element.setAttribute('menu_hover_switch_id', i + 1);
      element.addEventListener('mouseover', menuHoverSwitchImage);
    });
  }




  // || Animation Via Intersection observer

  // # Internet explorer fallback
  if (window.document.documentMode) {
    document.querySelectorAll('.u__animated').forEach(function (element) {
      element.classList.add('animate');
    });
  }

  // # Main Logic
  function inViewAnimate(entries) {
    entries.map((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add('animate');
      }
    });
  }

  /* Animation Framework */
  function initAnimationObserver() {
    let options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.4,
    };

    let observer = new IntersectionObserver(inViewAnimate, options);

    let animatedElements = document.querySelectorAll('.u__animated');

    animatedElements.forEach((element) => {
      observer.observe(element);
    });
  }
  initAnimationObserver();

  /* || Partials */

  /* # Grid */

  /* # Information Overlay > Slide down/up */
  const gridInfomationOverlayTriggers = document.querySelectorAll('[grid_information_overlay_trigger]');
  if (gridInfomationOverlayTriggers) {
    gridInfomationOverlayTriggers.forEach((element) => {
      element.addEventListener("click", (e) => {
        if (e.target.parentNode.querySelector('[grid_information_overlay_slide]')) {
          e.target.parentNode.querySelector('[grid_information_overlay_slide]').classList.toggle('show');
          e.target.parentNode.querySelector('[grid_information_overlay_button]').classList.toggle('show');
        }
      });
    });
  }

  /* ## Supplier Pop Up Grid */
  function initPopUpGrid() {
    let toggleModalTriggers = document.querySelectorAll('[supplier_grid_id]');
    if (toggleModalTriggers) {
      toggleModalTriggers.forEach((element) => {
        element.addEventListener('click', (e) => {
          let modalId = e.currentTarget.getAttribute('supplier_grid_id');
          let modal = document.querySelector('[supplier_grid_modal_id="' + modalId + '"]');
          document.querySelector('body').classList.toggle('modal-open');
          modal.classList.toggle('open');
        });
      });
    }
  }
  initPopUpGrid();

  /* ## Large Interactive Grid */
  // Content offset Adjuster
  let timeoutId;

  function updateTranslateLargeInteractiveGridTextContainer() {
    document.querySelectorAll('.large-interactive-grid__text-container').forEach((element) => {
      if (window.innerWidth > 1024) {
        let newHeight = parseInt(element.offsetHeight);
        let adjustedOffset = newHeight - 60;
        element.style['transform'] = `translateY(${adjustedOffset}px)`;
      } else {
        element.style['transform'] = `translateY(0)`;
      }
    });
  }

  function timeoutManagerTranslateLargeInteractiveGridTextContainer() {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(updateTranslateLargeInteractiveGridTextContainer, 500);
  }

  // Add resize event listner to window
  if (document.querySelectorAll('.large-interactive-grid__text-container')) {
    window.addEventListener("resize", timeoutManagerTranslateLargeInteractiveGridTextContainer);
  }

  // Initalise Resizer
  setTimeout(timeoutManagerTranslateLargeInteractiveGridTextContainer, 500);

  /* # FAQ */
  const faqItems = document.querySelectorAll('.faq-block button');

  function toggleFaq(e) {
    const triggerItem = e.currentTarget;

    document.querySelectorAll('.faq-button').forEach((buttonItem) => {
      buttonItem.setAttribute('aria-expanded', 'false');
      buttonItem.querySelector('div').classList.remove('open');
    });

    document.querySelectorAll('.faq-content').forEach((contentItem) => {
      contentItem.classList.remove('open');
    });

    triggerItem.setAttribute('aria-expanded', 'true');
    triggerItem.querySelector('div').classList.add('open');

    const content = triggerItem.parentNode.querySelector('.faq-content');
    if (content) {
      content.classList.add('open');
    }
  }

  faqItems.forEach((element) => {
    element.addEventListener('click', toggleFaq)
  });

  /* # Media and Text */
  /* ## Custom Iframe Code > Pop-out Window */

  let iframeLightboxTrigger = document.querySelector('[iframe_lightbox_trigger]');
  let iframeLightboxContainer = document.querySelector('[iframe_lightbox_container]');
  let iframeLightboxClose = document.querySelector('.iframe-lightbox-container__close i');

  if (iframeLightboxTrigger && iframeLightboxContainer && iframeLightboxClose) {
    iframeLightboxTrigger.addEventListener('click', (element) => {
      document.querySelector('[iframe_lightbox_container]').classList.add('open');
    });
    iframeLightboxClose.addEventListener('click', (element) => {
      document.querySelector('[iframe_lightbox_container]').classList.remove('open');
    });
  }

  /* # Calendly Intergration */
  function initCalendlyIntergration() {
    let headerCalendlyButton = document.querySelector('[calendly_button]');
    if (headerCalendlyButton) {
      headerCalendlyButton.addEventListener('click', () => {
        Calendly.initPopupWidget({ url: 'https://calendly.com/sales-tcm' });
        return false;
      });
    }
  }

  initCalendlyIntergration();


  document.addEventListener('DOMContentLoaded', (event) => {
    const hoverTarget = document.getElementById('whatsapp');
    const classTarget = document.getElementById('whatsapp-info');

    hoverTarget.addEventListener('mouseenter', () => {
      classTarget.classList.add('whatsapp-info-show');
    });

    hoverTarget.addEventListener('mouseleave', () => {
      classTarget.classList.remove('whatsapp-info-show');
    });
  });


});

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
import.meta.webpackHot?.accept(console.error);



