export default function() { 

}

export function initTieredAccordion() {
    console.log('initTieredAccordion');

    document.addEventListener('DOMContentLoaded', function () {
        const toggles = document.querySelectorAll('.accordion-toggle');
        
        toggles.forEach(function(toggle) {
            toggle.addEventListener('click', function(e) {
            e.preventDefault();
            // The submenu should be the next sibling element
            const submenu = toggle.parentElement.nextElementSibling;
            if (submenu) {
                submenu.classList.toggle('hidden');
                // Toggle the 'active' class on the toggle button
                toggle.classList.toggle('active');                
            }
            });
        });
    });
}