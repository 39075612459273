export default function() { 

}

export function initTieredAccordion() {

    document.addEventListener('DOMContentLoaded', function () {
        // Function to open the submenu, taking computed padding into account
        function openSubmenu(submenu, toggle) {
          // Force reflow so the browser picks up the new values
          submenu.offsetHeight;
          
          // Get computed styles
          const computedStyle = window.getComputedStyle(submenu);
          const paddingTop = parseFloat(computedStyle.paddingTop);
          const paddingBottom = parseFloat(computedStyle.paddingBottom);
        
          // Calculate the full height; scrollHeight often includes padding but adjust if needed
          let totalHeight = submenu.scrollHeight;
          totalHeight = totalHeight + paddingTop + paddingBottom;
          
          submenu.style.maxHeight = totalHeight + 'px';
          toggle.classList.add('active');
        }
        
        // Open any parent items that should be expanded at page load (using current-menu-parent)
        document.querySelectorAll('li.current-menu-parent').forEach((menuItem) => {
          const toggle = menuItem.querySelector('.accordion-toggle');
          if (toggle) {
            const submenu = toggle.parentElement.nextElementSibling;
            if (submenu) {
              openSubmenu(submenu, toggle);
            }
          }
        });
        
        // Set up click handlers on all accordion toggle buttons
        const toggles = document.querySelectorAll('.accordion-toggle');
        toggles.forEach((toggle) => {
          toggle.addEventListener('click', function (e) {
            e.preventDefault();
            const submenu = toggle.parentElement.nextElementSibling;
            if (!submenu) return;
            
            // If the clicked toggle's submenu is closed, close any other open menus first
            if (!submenu.style.maxHeight || submenu.style.maxHeight === '0px') {
              toggles.forEach((otherToggle) => {
                if (otherToggle !== toggle) {
                  const otherSubmenu = otherToggle.parentElement.nextElementSibling;
                  if (otherSubmenu && otherSubmenu.style.maxHeight && otherSubmenu.style.maxHeight !== '0px') {
                    otherSubmenu.style.maxHeight = '0px';
                    otherToggle.classList.remove('active');
                  }
                }
              });
            }
            
            // Toggle the clicked submenu open or closed
            if (submenu.style.maxHeight && submenu.style.maxHeight !== '0px') {
              // It's currently open; close it.
              submenu.style.maxHeight = '0px';
              toggle.classList.remove('active');
            } else {
              // It's closed; open it.
              openSubmenu(submenu, toggle);
            }
          });
        });
      });



}